import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 89vh;
  min-width: 130px;
  overflow: auto;
`;

export const PhotoCard = styled.button`
  border: 0;
  background-color: #fff;
  position: relative;
  border-radius: 8px;

  min-width: 110px;
  max-width: 110px;
  min-height: 110px;
  max-height: 110px;
  margin-top: 10px;
  ${(props) =>
    props.selected &&
    css`
      background: #a0bd39;
    `}

  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    position: absolute;
    align-self: center;
    width: 100px;
    height: 100px;
    /* opacity: 0.6; */
    border-radius: 8px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    position: absolute;
    bottom: 0;
    align-self: center;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100px;
    margin-bottom: 5px;
    border-radius: 0px 0px 8px 8px;
    padding: 5px 0px;
  }
`;
