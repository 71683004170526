/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  Fragment
} from 'react';
import Modal from 'react-modal';
import XLSX from 'xlsx';
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Paper,
  TableContainer,
  Badge,
  IconButton,
  CircularProgress
} from '@mui/material';
import HtmlToReactParser from 'html-to-react';
import InfoIcon from '@mui/icons-material/Newspaper';
import ArticleIcon from '@mui/icons-material/Article';
import ReactAudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  ArrowBack,
  Close,
  PlayCircleFilled,
  PlayCircleFilledOutlined
} from '@mui/icons-material';
import axios from 'axios';

import { useModal } from '../../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../../index';
import { useAuth } from '../../../../../../../../../hooks/Auth';
import api from '../../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../../config';
import * as S from './styled';

const parser = new HtmlToReactParser.Parser();

const ModaExportRoutine = () => {
  const { closeModal, modalIsOpen } = useModal();
  const { token } = useAuth();
  const { selectedMonitoring } = useContext(DashboardContext);
  const [view, setView] = useState({ place: 'points-info', data: null });

  const [monitoringReports, setMonitoringReports] = useState([]);
  const [mediasData, setMediasData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const formatDate = (date, symbol = '/') => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}${symbol}${mes}${symbol}${ano}`;
  };

  const sortArr = (data, field) => {
    return data.sort((a, b) => {
      const fieldA = a[field];
      const fieldB = b[field];
      if (fieldA < fieldB) {
        return -1;
      }
      if (fieldA > fieldB) {
        return 1;
      }
      return 0;
    });
  };

  const handleRequestMedias = async (routineId, parameter, reference, load) => {
    load(true);
    try {
      if (parameter === 'Daninhas') {
        parameter = 'Daninha';
      }

      if (parameter === 'Doenças') {
        parameter = 'Doença';
      }

      const url = `${appConfig.apiHosts.prague}/Routine/GetMediaByRoutineAndOrigin?routine_id=${routineId}&origin=${parameter}&reference=${reference}`;
      const opt = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.get(url, opt);
      setMediasData(response.data.data.medias);
    } catch (error) {
      console.error(error);
    } finally {
      load(false);
    }
  };

  const getHistoriesRoutine = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/HistoriesByRoutine?routine_id=${selectedMonitoring.routine_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const newArr = sortArr(response.data.data.historieRoutines, 'parameters');
      sortArr(newArr, 'point');

      const groupedData = newArr.reduce((acc, obj) => {
        const key = `${obj.parameters}-${obj.species}-${obj.point}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        obj.is_number = !isNaN(obj.value);
        obj.point = Number(obj.point) || 0;
        acc[key].push(obj);
        return acc;
      }, {});
      const groupedArray = Object.values(groupedData);

      const newGroupedArray = groupedArray.map((items) => {
        const sum = items.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.value),
          0
        );
        const average = sum / items.length || 0;

        items.forEach((item) => {
          if (item.is_number) {
            item.average = average.toFixed(2);
          } else {
            item.average = item.value;
          }
        });

        return items;
      });

      setMonitoringReports(newGroupedArray);
    } catch (error) {
      console.error(error);
    }
  };

  const getHours = (dataString) => {
    const formatedDate = new Date(dataString);

    const hours = formatedDate.toLocaleTimeString('pt-BR', { hour12: false });
    return hours;
  };

  const xport = useCallback(() => {
    const table = document.getElementById('Table2XLSX');
    const wb = XLSX.utils.table_to_book(table);

    XLSX.writeFile(
      wb,
      `monitoramento_de_campo_${formatDate(new Date(), '_')}.xlsx`
    );
  });

  useEffect(() => {
    getHistoriesRoutine();
  }, [selectedMonitoring]);

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      width: '800px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '950px',
      maxHeight: '85%'
    }
  };

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={() => {
        closeModal();
        setView({ data: null, place: 'points-info' });
      }}
      style={styles}
    >
      <S.Container>
        <div style={{ padding: '25px' }}>
          <button
            type="button"
            className="table__addButton"
            onClick={() => closeModal()}
          >
            <ArrowBack />
          </button>
          <S.TableTitle>RELATÓRIO DE MONITORAMENTO DE CAMPO</S.TableTitle>
          <S.TableHeader>
            <S.TableWrapper style={{ margin: 0 }}>
              <S.TableContainer>
                <S.SubTitle>Titulo:</S.SubTitle>
                <S.TextContainer>{selectedMonitoring?.title}</S.TextContainer>
              </S.TableContainer>
              <S.TableContainer>
                <S.SubTitle>Data Final:</S.SubTitle>
                <S.TextContainer>
                  {formatDate(selectedMonitoring?.end_date)}
                </S.TextContainer>
              </S.TableContainer>
            </S.TableWrapper>
            <S.TableWrapper style={{ margin: 0 }}>
              <S.TableContainer>
                <S.SubTitle>Técnico:</S.SubTitle>
                <S.TextContainer>
                  {selectedMonitoring?.executor_name}
                </S.TextContainer>
              </S.TableContainer>
              <S.TableContainer>
                <S.SubTitle>Talhão:</S.SubTitle>
                <S.TextContainer>
                  {selectedMonitoring?.encompass_name}
                </S.TextContainer>
              </S.TableContainer>
            </S.TableWrapper>
            <S.TableWrapper style={{ margin: 0 }}>
              <S.TableContainer>
                <S.SubTitle>Data Inicial:</S.SubTitle>
                <S.TextContainer>
                  {formatDate(selectedMonitoring?.init_date)}
                </S.TextContainer>
              </S.TableContainer>
              <S.TableContainer>
                <S.SubTitle>Horário:</S.SubTitle>
                <S.TextContainer>
                  {getHours(selectedMonitoring?.init_date)}
                </S.TextContainer>
              </S.TableContainer>
            </S.TableWrapper>
          </S.TableHeader>
        </div>
      </S.Container>
      <S.ContentContainer>
        {view.place === 'points-info' && (
          <TableContainer>
            <Table stickyHeader size="small" cellSpacing="0" id="Table2XLSX">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}
                >
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Ponto
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Ocorrência
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Parâmetro
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Sub parâmetro
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Valor
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Média
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Inf. Adic.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {monitoringReports &&
                  monitoringReports.map((monitoringReport, i) => (
                    <Fragment key={i}>
                      {monitoringReport.map((report, index) => (
                        <TableRow key={i + index} style={{ height: 50 }}>
                          {index === 0 && (
                            <TableCell
                              rowSpan={monitoringReport.length}
                              scope="rowgroup"
                              align="center"
                              sx={{
                                border: '1px solid rgba(224, 224, 224, 1)'
                              }}
                            >
                              {report.point}
                            </TableCell>
                          )}
                          {index === 0 && (
                            <TableCell
                              rowSpan={monitoringReport.length}
                              scope="rowgroup"
                              align="center"
                              sx={{
                                border: '1px solid rgba(224, 224, 224, 1)'
                              }}
                            >
                              {report.parameters}
                            </TableCell>
                          )}
                          {index === 0 && (
                            <TableCell
                              rowSpan={monitoringReport.length}
                              scope="rowgroup"
                              align="center"
                              sx={{
                                border: '1px solid rgba(224, 224, 224, 1)'
                              }}
                            >
                              {report.species}
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                          >
                            {report.field}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                          >
                            {report.value}
                          </TableCell>
                          {index === 0 && (
                            <TableCell
                              rowSpan={monitoringReport.length}
                              scope="rowgroup"
                              align="center"
                            >
                              {report.average}
                            </TableCell>
                          )}
                          {index === 0 && (
                            <TableCell
                              rowSpan={monitoringReport.length}
                              scope="rowgroup"
                              align="center"
                              sx={{
                                border: '1px solid rgba(224, 224, 224, 1)'
                              }}
                            >
                              {
                                report.medias === '0'
                                ?
                                null
                                :
                                (
                                <Badge
                                  badgeContent={report.medias}
                                  color="primary"
                                  onClick={async () => {
                                    console.log('medias', report.medias);
                                    if (report.medias === 0) {
                                      return;
                                    }
                                    setView({
                                      place: 'additional-info',
                                      data: null
                                    });
                                    await handleRequestMedias(
                                      selectedMonitoring.routine_id,
                                      report.parameters,
                                      report.reference,
                                      setFetching
                                    );
                                  }}
                                >
                                  <InfoIcon color="action" />
                                </Badge>
                                )
                              }
                            </TableCell>)
                          }
                        </TableRow>
                      ))}
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {view.place === 'additional-info' && (
          <>
            <IconButton
              onClick={() => setView({ data: null, place: 'points-info' })}
              style={{ width: 50, height: 50, alignSelf: 'flex-end' }}
            >
              <Close color="error" />
            </IconButton>

            {fetching ? (
              <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="success" />
                <p>Carregando...</p>
              </div>
            ) : (
              <>
                <S.MediasContentArea>
                  {mediasData.map((media, i) => {
                    if (media.mime_type === 'image') {
                      return (
                        <button
                          key={i + media.mime_type}
                          style={{
                            width: '30%',
                            height: 200,
                            backgroundColor: '#f2f2f2',
                            margin: 2
                          }}
                          onClick={() => {
                            setView({
                              place: 'media-info',
                              data: { content: media.data, type: 'image' }
                            });
                          }}
                        >
                          <img
                            src={`data:image/png;base64, ${media.data}`}
                            style={{ width: '100%', height: 200 }}
                          />
                        </button>
                      );
                    }

                    if (media.mime_type === 'audio') {
                      return (
                        <S.MediaContainer key={i + media.mime_type}>
                          <IconButton
                            onClick={() =>
                              setView({
                                place: 'media-info',
                                data: { type: 'audio', content: media.data }
                              })
                            }
                          >
                            <PlayCircleFilledOutlined
                              style={{ width: '100%', height: 200 }}
                            />
                          </IconButton>
                        </S.MediaContainer>
                      );
                    }

                    if (media.mime_type === 'text') {
                      return (
                        <S.MediaContainer key={i + media.mime_type}>
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              width: '100%',
                              height: 200,
                              borderStyle: 'none',
                              padding: 2
                            }}
                            onClick={() =>
                              setView({
                                place: 'media-info',
                                data: { type: 'text', content: media.data }
                              })
                            }
                          >
                            <ArticleIcon
                              style={{ width: '100%', height: 200 }}
                            />
                          </button>
                        </S.MediaContainer>
                      );
                    }

                    return null;
                  })}
                </S.MediasContentArea>
              </>
            )}
          </>
        )}
        {view.place === 'media-info' && (
          <>
            <IconButton
              onClick={() => setView({ data: null, place: 'additional-info' })}
              style={{ width: 50, height: 50, alignSelf: 'flex-end' }}
            >
              <Close color="error" />
            </IconButton>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: 420,
                flexDirection: 'row',
                justifyContent: 'space-around'
              }}
            >
              {view.data.type === 'image' && (
                <img
                  src={`data:image/png;base64, ${view.data.content}`}
                  aspect="auto"
                  style={{ aspectRatio: 6 / 4, width: '100%', height: 400 }}
                />
              )}

              {view.data.type === 'audio' && (
                <div style={{ width: '100%' }}>
                  <ReactAudioPlayer
                    src={`data:audio/mp3;base64, ${view.data.content}`}
                    autoPlay
                  />
                </div>
              )}

              {view.data.type === 'text' && (
                <div>{parser.parse(view.data.content)}</div>
              )}
            </div>
          </>
        )}
      </S.ContentContainer>
      <S.ExportButton>
        <button type="button" className="exportButton" onClick={xport}>
          EXPORTAR RESULTADOS
        </button>
      </S.ExportButton>
    </Modal>
  );
};

export default ModaExportRoutine;
