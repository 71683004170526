import React from 'react';
import { MdClose } from 'react-icons/md';

import { useMap } from '../../hooks/Map';
import * as S from './styled';

const SelectSatelliteCard = () => {
  const { setControlState, satellite, setSatellite } = useMap();
  return (
    <S.Container>
      <S.Header>
        <h1>Selecionar satélite</h1>
        <button
          type="button"
          onClick={() => {
            setControlState(undefined);
          }}
        >
          <MdClose />
        </button>
      </S.Header>
      <S.Form>
        <label htmlFor="sentinel-2-l1c">
          Sentinel-2 L1C
          <input
            type="radio"
            name="satellite"
            id="sentinel-2-l1c"
            defaultChecked={satellite === 'sentinel-2-l1c'}
            onChange={() => setSatellite('sentinel-2-l1c')}
          />
        </label>
        <label htmlFor="sentinel-2-l2a">
          Sentinel-2 L2A
          <input
            type="radio"
            name="satellite"
            id="sentinel-2-l2a"
            defaultChecked={satellite === 'sentinel-2-l2a'}
            onChange={() => setSatellite('sentinel-2-l2a')}
          />
        </label>
        <label htmlFor="landsat-ot-l1">
          Landsat 8
          <input
            type="radio"
            name="satellite"
            id="landsat-ot-l1"
            defaultChecked={satellite === 'landsat-ot-l1'}
            onChange={() => setSatellite('landsat-ot-l1')}
          />
        </label>
        <label htmlFor="dem">
          DEM
          <input
            type="radio"
            name="satellite"
            id="dem"
            defaultChecked={satellite === 'dem'}
            onChange={() => setSatellite('dem')}
          />
        </label>
        <label htmlFor="modis">
          MODIS
          <input
            type="radio"
            name="satellite"
            id="modis"
            defaultChecked={satellite === 'modis'}
            onChange={() => setSatellite('modis')}
          />
        </label>
        <label htmlFor="sentinel-1-grd">
          Sentinel-1
          <input
            type="radio"
            name="satellite"
            id="sentinel-1-grd"
            defaultChecked={satellite === 'sentinel-1-grd'}
            onChange={() => setSatellite('sentinel-1-grd')}
          />
        </label>
      </S.Form>
    </S.Container>
  );
};

export default SelectSatelliteCard;
