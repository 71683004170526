import { capaBase64 } from './reportBaseImages/capa';
import { contracapBase64 } from './reportBaseImages/contracapa';
import { dedicatoriaBase64 } from './reportBaseImages/dedicatoria';
import { finalBase64 } from './reportBaseImages/final';
import { footerBase64 } from './reportBaseImages/footer';
import { headerBase64 } from './reportBaseImages/header';
import { northBase64 } from './reportBaseImages/north';

export const imagesReport = {
  header: headerBase64,
  footer: footerBase64,
  capa: capaBase64,
  contracapa: contracapBase64,
  final: finalBase64,
  north: northBase64,
  decicatoria: dedicatoriaBase64
};
