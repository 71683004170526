/* eslint-disable @typescript-eslint/no-namespace */

import { appConfig } from '../config';
import api from './api';

export namespace SubdivisionsService {
  export async function getSubdivisionsByFarmId(
    farmId: string,
    userId: string,
    token: string
  ): Promise<void> {
    const params = new URLSearchParams([
      ['farmId', farmId],
      ['userId', userId]
    ]);

    const response = await api.get(
      `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    const { data } = response?.data;
    return data;
  }
}
