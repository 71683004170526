/* eslint-disable @typescript-eslint/no-namespace */

export namespace ThreadUtils {
  export async function milliseconds(ms: number): Promise<void> {
    return new Promise((resolve1) => {
      setTimeout(() => {
        return resolve1();
      }, ms);
    });
  }
}
