export enum SubdivisionFieldNames {
  Hectares = 'Hectares',
  NOME = 'NOME',
  Sub_Talhao = 'Sub_Talhao',
  FID_1 = 'FID_1',
  Cliente = 'Cliente',
  Fazenda = 'Fazenda',
  Talhao = 'Talhao',
  pH_Cacl2_1 = 'pH_Cacl2_1',
  pH_Cacl2_2 = 'pH_Cacl2_2',
  P_1 = 'P_1',
  P_2 = 'P_2',
  K_1 = 'K_1',
  K_2 = 'K_2',
  Ca_1 = 'Ca_1',
  Ca_2 = 'Ca_2',
  Mg_1 = 'Mg_1',
  Mg_2 = 'Mg_2',
  Al_1 = 'Al_1',
  Al_2 = 'Al_2',
  MO_1 = 'MO_1',
  MO_2 = 'MO_2',
  MO_NIVEIS = 'MO_NIVEIS',
  Argila_1 = 'Argila_1',
  Argila_2 = 'Argila_2',
  CTC_1 = 'CTC_1',
  CTC_2 = 'CTC_2',
  V_1 = 'V_1',
  V_2 = 'V_2',
  M_1 = 'M_1',
  M_2 = 'M_2',
  S_1 = 'S_1',
  S_2 = 'S_2',
  COD = 'COD',
  FID_2 = 'FID_2',
  INDICE = 'INDICE',
  COMENTARIO = 'COMENTARIO',
  ICONE = 'ICONE',
  ALTITUDE = 'ALTITUDE',
  DATA = 'DATA',
  ANGULO = 'ANGULO',
  DISPLAY = 'DISPLAY',
  ZONA = 'ZONA',
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE',
  Distance = 'Distance',
  Profundida = 'Profundida'
}

export const keysOfSubdivisionFieldNames = Object.keys(
  SubdivisionFieldNames
) as SubdivisionFieldNames[];
