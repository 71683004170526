/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { AxiosResponse } from 'axios';
import api from './configFarmApi';

class ProfileApi {
  constructor(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  get getProfiles() {
    return 'ListarPerfis';
  }

  get getProfile() {
    return 'ListarPerfis';
  }

  get ProfileId() {
    return 'profileId';
  }

  get ListaAtrelarPerfilModulo() {
    return 'Listar-Atrelar-PerfilModulo';
  }

  get ListaAtrelarPerfilModuloParaTabela() {
    return 'Listar-Atrelar-PerfilModulo-Para-Tabela';
  }

  get ListaAtrelarPerfilSubModulo() {
    return 'Listar-Atrelar-PerfilSubModulo';
  }

  async ProfileList(
    filtro: string,
    organizacao_id?: string,
    userId?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarPerfis?filtro=${filtro}&organizacao_id=${organizacao_id}&user_id=${
        userId ?? ''
      }`
    );
  }

  async GetProfile(
    id: string,
    organizacao_id?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/RetornarPerfil?id=${id}&organizacao_id=${organizacao_id}`
    );
  }

  async ProfileCreate<T>(data: T): Promise<AxiosResponse> {
    return api.post('/Farm/Perfil', data);
  }

  async ProfileUpdate<T>(data: T): Promise<AxiosResponse> {
    return api.put('/Farm/Perfil', data);
  }

  async ListarAtrelarPerfilModulo(
    filtro: string,
    userProfileId?: string,
    organizacaoId?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarAtrelarPerfilModulo?filtro=${filtro}&user_profile_id=${
        userProfileId ?? ''
      }&organizacao_id=${organizacaoId ?? ''}`
    );
  }

  async ListarAtrelarPerfilSubmodulo(
    filtro: string,
    userProfileId?: string,
    organizacaoId?: string
  ): Promise<AxiosResponse> {
    return api.get(
      `/Farm/ListarAtrelarPerfilSubmodulo?user_profile_id=${
        userProfileId || ''
      }&filtro=${filtro}&organizacao_id=${organizacaoId ?? ''}`
    );
  }

  async AtrelarPerfilSubmodulo(data: any): Promise<AxiosResponse> {
    const result = await api.put(
      `/Farm/AtrelarPerfilSubmodulo?user_profile_id=${data.user_profile_id}&sub_module_id=${data.sub_module_id}&acronym=${data.acronym}`
    );
    return result;
  }

  async DeletarPerfilSubmodulo(data: any): Promise<AxiosResponse> {
    const result = await api.delete(
      `/Farm/DeletarPerfilSubmodulo?user_profile_id=${data.user_profile_id}&sub_module_id=${data.sub_module_id}&acronym=${data.acronym}`
    );
    return result;
  }
}

export default ProfileApi;
