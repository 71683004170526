import React, { useEffect, useState } from 'react';
// import { MdRefresh } from 'react-icons/md';
import { useMap } from '../../hooks/Map';
import * as S from './styled';

const DynamicLegend = () => {
  const {
    readLayers,
    selectedFarmId,
    hideLayer,
    activeLayers,
    importedShapefileName,
    drawing
  } = useMap();
  const [checkedFarm, setCheckedFarm] = useState(true);
  const [checkedField, setCheckedField] = useState(true);
  const [checkedWalk, setCheckedWalk] = useState(true);
  const [checkedSubdivision, setCheckedSubdivision] = useState(true);
  const [checkedPoints, setCheckedPoints] = useState(true);
  const [checkedDepth, setCheckedDepth] = useState(true);
  const [checkedImported, setCheckedImported] = useState(true);
  const [checkedRecommZone, setCheckedRecommZone] = useState(true);

  useEffect(() => {
    readLayers();
  }, [selectedFarmId]);

  return (
    activeLayers?.length > 0 &&
    drawing === false && (
      <S.Container>
        <S.DynamicLegendHeader>
          <S.Title>Legenda</S.Title>
          {/* <button
          type="button"
          onClick={() => {
            readLayers();
          }}
        >
          <MdRefresh />
        </button> */}
        </S.DynamicLegendHeader>
        <S.LegendData>
          {!!activeLayers.find((f) => !!f.farm) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.FarmLimits />
              </S.LegendIconItem>
              <S.LegendItemText>Limites da Fazenda</S.LegendItemText>
              <input
                type="checkbox"
                name="farm_limits"
                onClick={() => {
                  hideLayer('farm');
                  setCheckedFarm(!checkedFarm);
                }}
                checked={checkedFarm}
              />
            </S.LegendItem>
          )}
          {activeLayers.find((f) => !!f.field) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.FieldLimits />
              </S.LegendIconItem>
              <S.LegendItemText>Limite dos Talhões</S.LegendItemText>
              <input
                type="checkbox"
                name="field_limits"
                onClick={() => {
                  hideLayer('field');
                  setCheckedField(!checkedField);
                }}
                checked={checkedField}
              />
            </S.LegendItem>
          )}
          {activeLayers.find((f) => !!f.walk) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.WalkLimits />
              </S.LegendIconItem>
              <S.LegendItemText>Caminhamentos</S.LegendItemText>
              <input
                type="checkbox"
                name="walk_limits"
                onClick={() => {
                  hideLayer('walk');
                  setCheckedWalk(!checkedWalk);
                }}
                checked={checkedWalk}
              />
            </S.LegendItem>
          )}
          {activeLayers.find((f) => !!f.subdivision) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.SubdivisionLimits />
              </S.LegendIconItem>
              <S.LegendItemText>Limites da Subdivisão</S.LegendItemText>
              <input
                type="checkbox"
                name="subdivision"
                onClick={() => {
                  hideLayer('subdivision');
                  setCheckedSubdivision(!checkedSubdivision);
                }}
                checked={checkedSubdivision}
              />
            </S.LegendItem>
          )}
          {activeLayers.find((f) => !!f.points) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.Point />
              </S.LegendIconItem>
              <S.LegendItemText>Pontos de Coleta</S.LegendItemText>
              <input
                type="checkbox"
                name="points"
                onClick={() => {
                  hideLayer('points');
                  setCheckedPoints(!checkedPoints);
                }}
                checked={checkedPoints}
              />
            </S.LegendItem>
          )}
          {activeLayers.find((f) => !!f.depth) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.Point />
              </S.LegendIconItem>
              <S.LegendItemText>Pontos de Profundidade</S.LegendItemText>
              <input
                type="checkbox"
                name="depth"
                onClick={() => {
                  hideLayer('depth');
                  setCheckedDepth(!checkedDepth);
                }}
                checked={checkedDepth}
              />
            </S.LegendItem>
          )}
          {activeLayers.find((f) => !!f.imported) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.Imported />
              </S.LegendIconItem>
              <S.LegendItemText>{importedShapefileName}</S.LegendItemText>
              <input
                type="checkbox"
                name="imported"
                onClick={() => {
                  hideLayer('imported');
                  setCheckedImported(!checkedImported);
                }}
                checked={checkedImported}
              />
            </S.LegendItem>
          )}
          {activeLayers.find((f) => !!f.recommZone) && (
            <S.LegendItem>
              <S.LegendIconItem>
                <S.RecommZone />
              </S.LegendIconItem>
              <S.LegendItemText>Zona de Recomendação</S.LegendItemText>
              <input
                type="checkbox"
                name="recommZone"
                onClick={() => {
                  hideLayer('recommZone');
                  setCheckedRecommZone(!checkedRecommZone);
                }}
                checked={checkedRecommZone}
              />
            </S.LegendItem>
          )}
        </S.LegendData>
      </S.Container>
    )
  );
};

export default DynamicLegend;
